
<template>  
    <template v-if="!isSBProject()">
        <div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Sed rutrum quam sit amet elit scelerisque ullamcorper. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In euismod rutrum dui, et pulvinar libero hendrerit et. Sed suscipit quam non velit scelerisque, ut finibus arcu aliquet.
                Vivamus ullamcorper interdum scelerisque. Duis commodo diam eget nulla lobortis molestie. Suspendisse sagittis nibh est, sit amet porttitor eros dictum eu. Donec quis eros sit amet justo semper accumsan.
                Praesent venenatis mollis sem eget tempus. Duis accumsan tempor ex, non dictum leo hendrerit eu. Sed posuere egestas est vitae egestas. Ut porta eget metus non sodales. Nullam in dictum tellus, pretium placerat felis.
            </p>
        </div>
    </template>
    <template v-else>
        <h1>
            <b> 
                Bakgrund 
            </b>
        </h1>
        <div>
            <p><b>Kom igång med barter idag!</b></p>
            <p>Det finns många goda skäl till att fler och fler företag världen över erbjuder sina produkter och tjänster på barter istället för kontanter, men underliggande för dem alla är en grundläggande affärsmotivering: företagets vinst.</p>
            <p>Flygbolag och restauranger kan fylla tomma platser, hotell kan fylla tomma rum, tryckfirmor kan fylla trycktid, konsulter kan fylla tomma tidsluckor, mediabolag fyller tomma annonsplatser och så vidare.</p>
            <p>Företagare kan sedan ta den här nyinkomna inkomsten och minska de befintliga kontanta utgifterna eller investera för att utöka sin verksamhet. Eller varför inte använda dem till CSR-satsningar?</p>
            <p>Allt fler företag, över hela världen, ser idag barter som ett smart sätt att bygga hållbarhet och tillväxt i sin verksamhet. Om detta vittnar den snabba tillväxten av barterbranschen internationellt. Nu finns äntligen en möjlighet i svensk tappning. Svensk Barter är en modern lösning för SME-sektorn, föreningslivet och den sociala företagssektorn att bidra till lokal hållbarhet i en globaliserad värld.</p>
            <p><b> - ÖKA FÖRSÄLJNINGEN MED BARTERAVTAL:</b> Föreningen marknadsför ditt företag till andra medlemmar via webshopen, e-postutskick, handelsmöten med mera. Ditt företag hänvisas till helt nya kunder du annars inte fått. Medlemmarna hjälps även åt med rekryteringen av nya medlemmar vars varor och tjänster efterfrågas i nätverket. Medlemmarna är också med och sköter föreningen och dess aktiviteter. Välkommen att bidra till detta arbete till nytta för både dig själv och alla medlemmar i föreningen. </p>
            <p><b> - ÖKA DEN VANLIGA FÖRSÄLJNINGEN:</b> Den främsta källan till reklam för småföretag är "word of mouth". Om du gör ett bra jobb för en barterkund, kommer den att hänvisa sina kontantbetalande vänner, kunder, familj och andra företag till dig. Dessutom kommer barterintäkter från föreningen att kunna användas för radio, tv, trycksaker, direktreklam, utomhusreklam, onlinekampanjer och andra typer av reklam, beroende på vad som för tillfället finns i barterringen, för att locka fler kunder och bygga ditt varumärke.</p>
            <p><b> - HANDLA ”SOM PÅ RABATT”:</b> Med barteravtal är den faktiska kostnaden för de varor och tjänster som du köper rabatterad motsvarande säljmarginalen på dina egna varor och tjänster. Exempelvis om du har ett hotell och ett rum kostar 300 SEK att städa och hyra ut till ett slutkundpris av 1000 SEK per natt får du 70% rabatt på allt i barterringen som du byter detta rum mot. Låt säga att du köper marknadsföring och städtjänster i barterringen till ett värde av 100 000 SEK, så blir din faktiska kostnad 30 000 SEK. Moms betalas som vanligt från ditt ordinarie bankkonto till säljaren (eller på barter, enligt överenskommelse).</p>
            <p><b> - AVYTTRA ÖVERSKOTTSLAGER & FYLL DIN KUNDKALENDER:</b> Varje affärsidkare har då och då överflödig lagerhållning, produktion, kapacitet eller arbetstid. Det obokade konsultmötet idag har noll värde imorgon och intäktsmöjligheten är förlorad för alltid. När du går med i Svensk Barter erbjuds potentiella nya affärer som får ditt överskott att användas på ett mer lönsamt sätt. Detta åstadkommes genom att matcha din produkt eller tjänst med företag som vill köpa dem på barteravtal. Det är viktigt att medlemmar accepterar att köpa även under lågsäsong för att detta ska fungera smidigt.</p>
            <p><b> - ÖKA KONKURRENSKRAFTEN:</b> Medlemmar i barterringen kommer troligen att välja dig före konkurrenterna, eftersom ditt eget medlemskap är ett incitament att göra affärer med dig. Barterhandeln lockar nya kunder till ditt företag, utan att påverka den befintliga kontanta försäljningen (annat än möjligen positivt).</p>
            <p><b> - INGA DÅLIGA FORDRINGAR:</b> Vi eliminerar risken för dåliga fordringar mellan medlemmar. När du godkänner en försäljning i barterringen verifierar handelsplattformen att köparen har tillräckligt med barterkronor på sitt handelskonto att betala med innan köpet kan gå igenom.</p>
            <p><b> - TA BETALT I NATURA:</b> Ett litet företag kan ibland ha svårt att betala de pengar de är skyldiga, men skulle välkomna möjligheten att uppfylla sin skyldighet med betalning i egna produkter eller tjänster. Medlemmar i Svensk Barter kan då erbjuda dessa varor och tjänster som de erhåller utanför barterringen via direkta barteravtal till andra medlemmar mot barterkronor. Din annars icke-inkasserade fordran blir på detta sätt möjlig att lösa in och omsättas till en mängd olika varor och tjänster.</p>
            <p><b> - PERSONALBELÖNINGAR:</b> Genom att köpa friskvård och gåvor till dina medarbetare för barterkronor kan du öka din attraktivitet som arbetsgivare och främja hälsan hos din personal. Barterinköp kan också vara ett bra sätt att skaffa tävlingspriser, bonusar, friskvårdsprogram och semester - allt tillgängligt för dig utan att använda kontanter, och med din säljmarginal som ”rabatt”. Genom sina kontakter kan Svensk Barter i vissa fall ordna internationella handelsmöjligheter för sina medlemmar. Turism, boende och marknadsföring i utlandet på barteravtal kan för vissa medlemmar troligen vara en mycket intressant möjlighet. Medlemmar som önskar vara med och utveckla detta spår är välkomna att ta kontakt.</p>

            <p>Sist men inte minst: Svensk Barter blir vad medlemmarna gör det till! Jobba med andra medlemmar för att hitta affärer och stärka föreningen så har du också möjlighet att öka din omsättning och förbättra årsresultatet!</p>
            <p><b>Här är en tänkbar vision:</b></p>
        </div>
        <div>
            <img src="../../assets/Svensk-barter-ide-diagram.png" alt="Svensk-barter-ide-diagram">
        </div>
    </template>
</template>

<script>
export default {
  methods: {
    isSBProject () {
      if (process.env.VUE_APP_NAME === 'SB') {
        return true
      }
      return false
    }
  }
}
</script>


<style scoped>  
    h1 {
        padding: 10px 0px 10px 0px;
        font-size: 1.6rem;
    }
    p {
        padding: 0px;
        font-size: 1rem;
        font-weight: normal;
    }
</style>
