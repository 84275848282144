<!--All text här är i nuläget hårdkodad och översatt till html via wordtohtml.net. 
Detta kommer i framtiden behöva ändras så att admin kan ändra texten via adminpage,
och att texten ligger sparad i databasen istället för att vara hårdkodad här.-->
<template>
    <template v-if="!isSBProject()">
        <div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Sed rutrum quam sit amet elit scelerisque ullamcorper. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In euismod rutrum dui, et pulvinar libero hendrerit et. Sed suscipit quam non velit scelerisque, ut finibus arcu aliquet.
                Vivamus ullamcorper interdum scelerisque. Duis commodo diam eget nulla lobortis molestie. Suspendisse sagittis nibh est, sit amet porttitor eros dictum eu. Donec quis eros sit amet justo semper accumsan.
                Praesent venenatis mollis sem eget tempus. Duis accumsan tempor ex, non dictum leo hendrerit eu. Sed posuere egestas est vitae egestas. Ut porta eget metus non sodales. Nullam in dictum tellus, pretium placerat felis.
            </p>
        </div>
    </template>
    
        <!--
        <p><strong>{{ $t('faq.purpose') }}</strong></p>
        {{ $t('faq.purpose_text')  }}

        <p><strong>{{ $t('faq.background') }}</strong></p>
        {{ $t('faq.background_text') }}

        <p><strong>{{ $t('faq.guidelines') }}</strong></p>
        {{ $t('faq.guidelines_text') }}
        -->
    <template v-else>
        <p><strong>Syfte</strong></p>
        <p>Vi v&auml;rnar om din integritet. Du ska kunna k&auml;nna dig trygg n&auml;r du anf&ouml;rtror oss dina personuppgifter. D&auml;rf&ouml;r har vi uppr&auml;ttat den h&auml;r policyn. Den utg&aring;r fr&aring;n g&auml;llande dataskyddslagstiftning och f&ouml;rtydligar hur vi jobbar f&ouml;r att ta tillvara dina r&auml;ttigheter och din integritet.</p>
        <p>Syftet med den h&auml;r policyn &auml;r att du ska f&aring; veta hur vi behandlar dina personuppgifter, vad vi anv&auml;nder dem till, vilka som f&aring;r ta del av dem och under vilka f&ouml;ruts&auml;ttningar samt hur du kan ta tillvara dina r&auml;ttigheter.</p>
        <p><strong>Bakgrund</strong></p>
        <p>Vi behandlar dina personuppgifter fr&auml;mst f&ouml;r att fullf&ouml;lja v&aring;ra f&ouml;rpliktelser mot dig. V&aring;r utg&aring;ngspunkt &auml;r att inte behandla fler personuppgifter &auml;n vad som beh&ouml;vs f&ouml;r &auml;ndam&aring;let, och vi str&auml;var alltid efter att anv&auml;nda de minst integritetsk&auml;nsliga uppgifterna.</p>
        <p>Vi beh&ouml;ver ocks&aring; dina personuppgifter f&ouml;r att ge dig bra service exempelvis vad g&auml;ller marknadsf&ouml;ring, barterm&auml;klarst&ouml;d och information. Vi kan ocks&aring; beh&ouml;va dina personuppgifter f&ouml;r att uppfylla lagar samt utf&ouml;ra kund- och marknadsanalyser.</p>
        <p>Du har r&auml;tt att mots&auml;tta dig att vi anv&auml;nder personuppgifter f&ouml;r direktmarknadsf&ouml;ring. N&auml;r vi samlar in personuppgifter om dig f&ouml;r f&ouml;rsta g&aring;ngen f&aring;r du mer information om detta och hur du kan mots&auml;tta dig det.</p>
        <h4>Riktlinjer</h4>
        <p>Vilka personuppgifter behandlar vi?</p>
        <p>Vi behandlar endast personuppgifter n&auml;r vi har laglig grund. Vi behandlar inte personuppgifter i annat fall &auml;n n&auml;r de beh&ouml;vs f&ouml;r att fullg&ouml;ra f&ouml;rpliktelser enligt avtal och lag. H&auml;r f&ouml;ljer exempel p&aring; personuppgifterna vi behandlar:</p>
        <ul>
            <li>
                <p>{{ $t('user.billingnamelabel') }}</p>
            </li>
            <li>
                <p>{{ $t('user.street_address') }}</p>
            </li>
            <li>
                <p>{{ $t('login.email_label') }}</p>
            </li>
            <li>
                <p>Telefonnummer</p>
            </li>
            <li>
                <p>&Aring;lder</p>
            </li>
            <li>
                <p>F&ouml;delsedatum</p>
            </li>
            <li>
                <p>K&ouml;n</p>
            </li>
            <li>
                <p>Titel</p>
            </li>
            <li>
                <p>Fotografier</p>
            </li>
            <li>
                <p>Bankrelaterade uppgifter som bankkonto f&ouml;r anst&auml;llda och medlemmar</p>
            </li>
            <li>
                <p>Uppgifter som du registrerade sj&auml;lvmant och frivilligt uppger</p>
            </li>
            <li>
                <p>Inneh&aring;ll som du sj&auml;lv publicerar, s.k. anv&auml;ndargenererat inneh&aring;ll</p>
            </li>
        </ul>
        <h4>Hur f&aring;r vi tillg&aring;ng till dina personuppgifter?</h4>
        <p>Vi f&ouml;rs&ouml;ker i m&ouml;jligaste m&aring;n att inh&auml;mta ditt samtycke innan vi b&ouml;rjar behandlar dina personuppgifter. Det g&ouml;r vi genom att l&aring;ta dig godk&auml;nna muntligen, l&aring;ta dig fylla i samtyckesblankett eller sj&auml;lv f&ouml;ra in uppgifterna digitalt i de fall behandlingen grundas p&aring; samtycke.</p>
        <p>Du har n&auml;r som helst r&auml;tt att &aring;terkalla ditt samtycke. Vi kommer d&aring; inte l&auml;ngre att behandla dina personuppgifter eller inh&auml;mta nya, under f&ouml;ruts&auml;ttning att det inte beh&ouml;vs f&ouml;r att fullg&ouml;ra v&aring;ra skyldigheter enligt avtal eller lag. T&auml;nk p&aring; att &aring;terkallelse av samtycke kan inneb&auml;ra att vi inte kan fullg&ouml;ra de skyldigheter vi har i f&ouml;rh&aring;llande till dig.</p>
        <p>Vi f&aring;r ocks&aring; tillg&aring;ng till dina personuppgifter p&aring; f&ouml;ljande s&auml;tt:</p>
        <ul>
            <li>
                <p>Uppgifter som du tillhandah&aring;ller oss direkt</p>
            </li>
            <li>
                <p>Uppgifter som registreras n&auml;r du bes&ouml;ker v&aring;r hemsida/handelsplattform</p>
            </li>
            <li>
                <p>Uppgifter som vi f&aring;r fr&aring;n offentliga register</p>
            </li>
            <li>
                <p>Uppgifter som vi f&aring;r n&auml;r du anlitar en av v&aring;ra medarbetare</p>
            </li>
            <li>
                <p>Uppgifter som vi f&aring;r n&auml;r du anm&auml;ler dig till v&aring;ra m&ouml;ten eller seminarier</p>
            </li>
            <li>
                <p>Uppgifter som vi f&aring;r n&auml;r du anm&auml;ler dig till nyhetsbrev och andra utskick</p>
            </li>
            <li>
                <p>Uppgifter som vi f&aring;r n&auml;r du svarar p&aring; enk&auml;ter och unders&ouml;kningar</p>
            </li>
            <li>
                <p>Uppgifter som vi f&aring;r n&auml;r du kontaktar oss, s&ouml;ker anst&auml;llning hos oss, bes&ouml;ker oss eller p&aring; annat s&auml;tt tar kontakt med oss</p>
            </li>
        </ul>
        <h4>Vilken information ger vi till dig?</h4>
        <p>N&auml;r vi samlar in dina personuppgifter f&ouml;r f&ouml;rsta g&aring;ngen kommer vi att informera dig om hur vi har f&aring;tt tag i personuppgifterna, vad vi kommer att anv&auml;nda dem till, vilka r&auml;ttigheter du har enligt dataskyddslagstiftningen och hur du kan ta tillvara dessa. Du kommer ocks&aring; att bli informerad om vem som &auml;r ansvarig f&ouml;r personuppgiftsbehandlingen och hur du kan kontakta oss om du har fr&aring;gor eller beh&ouml;ver l&auml;mna en beg&auml;ran eller f&ouml;rfr&aring;gan som avser dina personuppgifter och/eller r&auml;ttigheter.</p>
        <h4>Behandlas dina personuppgifter p&aring; ett betryggande s&auml;tt?</h4>
        <p>Vi utarbetar rutiner och arbetss&auml;tt f&ouml;r att dina personuppgifter ska hanteras p&aring; ett s&auml;kert s&auml;tt. Utg&aring;ngspunkten &auml;r att endast arbetstagare och andra personer inom organisationen som beh&ouml;ver personuppgifterna f&ouml;r att utf&ouml;ra sina arbetsuppgifter samt vid behov andra medlemmar som vill g&ouml;ra aff&auml;rer med dig ska ha tillg&aring;ng till dem.</p>
        <p>I fr&aring;ga om k&auml;nsliga personuppgifter inr&auml;ttar vi s&auml;rskilda beh&ouml;righetskontroller vid behov, vilket inneb&auml;r ett h&ouml;gre skydd f&ouml;r dina personuppgifter. Vi avser att inte behandla k&auml;nsliga personuppgifter s&aring; l&aring;ngt det &auml;r m&ouml;jligt.</p>
        <p>Vi &ouml;verf&ouml;r inte personuppgifter i andra fall &auml;n de som uttryckligen anges i denna policy eller annat &auml;n vad som stipuleras av GDPR och svensk lagstiftning.</p>
        <h4>N&auml;r l&auml;mnar vi ut dina personuppgifter?</h4>
        <p>V&aring;r utg&aring;ngspunkt &auml;r att inte l&auml;mna ut dina personuppgifter till tredje part om du inte har samtyckt till det eller om det inte &auml;r n&ouml;dv&auml;ndigt f&ouml;r att uppfylla v&aring;ra f&ouml;rpliktelser enligt avtal eller lag. I de fall vi l&auml;mnar ut personuppgifter till tredje part uppr&auml;ttar vi sekretessavtal samt s&auml;kerst&auml;ller att personuppgifterna behandlas p&aring; ett betryggande s&auml;tt.</p>
        <h4>Ansvar</h4>
        <p>Svensk Barter ekonomisk f&ouml;rening &auml;r personuppgiftsansvarig, vilket inneb&auml;r att vi &auml;r ansvariga f&ouml;r hur dina personuppgifter behandlas och att dina r&auml;ttigheter tas tillvara.</p>
        <h3>***</h3>
        <h3>Information om v&aring;r behandling av integritet p&aring; www.svenskbarter.se och relaterade kommunikationskanaler</h3>
        <p>F&ouml;r v&aring;r handelsplattform www.svenskbarter.se utg&aring;r vi fr&aring;n f&ouml;reningens integritetspolicy med n&aring;gra f&ouml;rtydligande principer:</p>
        <p>Vi ber dig inte om personlig information om vi inte beh&ouml;ver det.</p>
        <p>Vi delar inte din personliga information med n&aring;gon f&ouml;rutom f&ouml;r att f&ouml;lja lagen, utveckla v&aring;ra produkter eller skydda v&aring;ra r&auml;ttigheter.</p>
        <p>Vi lagrar inte personlig information p&aring; v&aring;ra servrar om det inte beh&ouml;vs f&ouml;r den p&aring;g&aring;ende driften av n&aring;gon av v&aring;ra tj&auml;nster.</p>
        <p>P&aring; v&aring;r blogg och forum str&auml;var vi efter att g&ouml;ra det s&aring; enkelt som m&ouml;jligt f&ouml;r dig att kontrollera vad som &auml;r synligt f&ouml;r allm&auml;nheten, ses av s&ouml;kmotorer, h&aring;lls privat och permanent raderad.</p>
        <p>Nedan f&ouml;ljer detaljer kring hur vi v&auml;rnar din integritet som anv&auml;ndare av handelsplattformen p&aring; svenskbarter.se:</p>
        <p>Liksom de flesta webbplatsoperat&ouml;rer samlar svenskbarter.se icke-personligt identifierande information av det slag som webbl&auml;sare och servrar brukar g&ouml;ra tillg&auml;ngliga, till exempel webbl&auml;sartyp, spr&aring;kinst&auml;llningar, referenswebbplats och datum och tid f&ouml;r varje f&ouml;rfr&aring;gan fr&aring;n bes&ouml;kare. V&aring;rt syfte att samla in personligt identifierande information &auml;r att b&auml;ttre f&ouml;rst&aring; hur bes&ouml;kare anv&auml;nder svenskbarter.se. Fr&aring;n tid till annan kan Svensk Barter sl&auml;ppa icke-personligt identifierande information, genom att publicera en rapport om trender kring anv&auml;ndningen av sin webbplats.</p>
        <p>Svenskbarter.se samlar ocks&aring; potentiellt personligt identifierande information som Internet Protocol (IP) adresser. Svensk Barter anv&auml;nder emellertid inte s&aring;dan information f&ouml;r att identifiera sina bes&ouml;kare, f&ouml;rutom under samma omst&auml;ndigheter som f&ouml;reningen anv&auml;nder och beskriver personligt identifierande information, s&aring; som beskrivs nedan.</p>
        <p>De som engagerar sig i transaktioner med Svensk Barter genom att bli medlemmar och k&ouml;pa en online byteshandelsservice, till exempel, uppmanas att tillhandah&aring;lla ytterligare information, inklusive n&ouml;dv&auml;ndig personlig och finansiell information som kr&auml;vs f&ouml;r att behandla dessa transaktioner. I varje fall samlar Svensk Barter endast in s&aring;dan information i den m&aring;n det &auml;r n&ouml;dv&auml;ndigt eller l&auml;mpligt f&ouml;r att uppfylla syftet med bes&ouml;karens interaktion med svenskbarter.se. Svensk Barter avsl&ouml;jar inte personligt identifierande information annat &auml;n vad som beskrivs nedan. Bes&ouml;kare kan alltid v&auml;gra att leverera personligt identifierande information, med f&ouml;rbeh&aring;llet att det kan hindra dem fr&aring;n att engagera sig i online-handel och vissa webbplatsrelaterade aktiviteter.</p>
        <h4>Skydd av viss personligt identifierande information</h4>
        <p>Svensk Barter avsl&ouml;jar potentiellt personligt identifierande och personligt identifierande information endast f&ouml;r de anst&auml;llda, entrepren&ouml;rer och anslutna organisationer som (i) beh&ouml;ver veta den informationen f&ouml;r att kunna bearbeta den f&ouml;r Svensk Barters r&auml;kning eller att tillhandah&aring;lla tj&auml;nster tillg&auml;ngliga vid Svensk Barters webbplatser, och (ii) som har kommit &ouml;verens om att inte l&auml;mna ut det till andra. N&aring;gra av dessa anst&auml;llda, entrepren&ouml;rer och anknutna organisationer kan vara placerade utanf&ouml;r ditt hemland. Genom att anv&auml;nda Svensk Barters webbplatser godk&auml;nner du &ouml;verf&ouml;ring av s&aring;dan information till dem. Svensk Barter kommer inte att hyra eller s&auml;lja potentiellt personligt identifierande och personligt identifierande information till n&aring;gon annan.</p>
        <p>Ut&ouml;ver sina anst&auml;llda, entrepren&ouml;rer och anknutna organisationer, som beskrivits ovan, s&aring; avsl&ouml;jar Svensk Barter potentiellt personligt identifierande och personligt identifierande information endast vid godk&auml;nnande av ber&ouml;rd individ eller n&auml;r det kr&auml;vs enligt lag.</p>
        <p>Svensk Barter beskriver ocks&aring; potentiellt personligt identifierande och personligt identifierande information samt transaktionsinformation och barterf&ouml;rs&auml;ljningsdata f&ouml;r svenska myndigheter, exempelvis skatteverket, p&aring; beg&auml;ran s&aring; l&auml;nge det &auml;r i enlighet med svensk lag.</p>
        <p>Om du &auml;r en registrerad anv&auml;ndare av en svensk barter-webbplats och har levererat din e-postadress kan Svensk Barter ibland skicka ett mail f&ouml;r att ber&auml;tta om nya funktioner, beg&auml;ra din feedback eller bara h&aring;lla dig uppdaterad med vad som h&auml;nder hos Svensk Barter och v&aring;ra f&ouml;retagstj&auml;nster och produkter. Vi anv&auml;nder fr&auml;mst v&aring;r blogg f&ouml;r att kommunicera denna typ av information, s&aring; du kan f&ouml;rv&auml;nta dig att erh&aring;lla den h&auml;r typen av e-post till ett minimum. Om du skickar en beg&auml;ran till oss (till exempel via ett support-e-postmeddelande eller via en av v&aring;ra feedbackmekanismer), f&ouml;rbeh&aring;ller vi oss r&auml;tten att publicera den f&ouml;r att hj&auml;lpa oss att klarg&ouml;ra eller svara p&aring; din beg&auml;ran eller hj&auml;lpa oss att st&ouml;dja andra anv&auml;ndare. Svensk Barter tar alla &aring;tg&auml;rder som &auml;r rimligt n&ouml;dv&auml;ndiga f&ouml;r att skydda mot obeh&ouml;rig tillg&aring;ng, anv&auml;ndning, &auml;ndring eller f&ouml;rst&ouml;relse av potentiellt personligt identifierande och personligt identifierande information.</p>
        <h4>Sm&aring;kakor</h4>
        <p>En cookie &auml;r en str&auml;ng information som en webbplats lagrar p&aring; en bes&ouml;kares dator och att bes&ouml;karens webbl&auml;sare ger till webbplatsen varje g&aring;ng bes&ouml;karen returnerar. Svenskbarter.se anv&auml;nder cookies f&ouml;r att hj&auml;lpa Svensk Barter att identifiera och sp&aring;ra bes&ouml;kare, deras anv&auml;ndning av svenskbarter.se-hemsidan, och deras webbplats preferenser. Anv&auml;ndare p&aring; Svenskbarter.se som inte vill ha cookies placerade p&aring; sina datorer b&ouml;r s&auml;tta sina webbl&auml;sare att neka cookies innan de anv&auml;nder hemsidor tillh&ouml;rande Svensk Barter, med nackdelen att vissa funktioner p&aring; dessa hemsidor kanske inte fungerar p&aring; r&auml;tt s&auml;tt utan hj&auml;lp av cookies.</p>
        <h4>&Auml;ndringar i integritetspolicy</h4>
        <p>Svensk Barter kan &auml;ndra eller uppdatera sin Integritetspolicy efter eget gottfinnande. D&auml;rf&ouml;r uppmuntrar Svensk Barter anv&auml;ndare att &aring;terkommande bes&ouml;ka denna sida f&ouml;r eventuella &auml;ndringar i sin sekretesspolicy. Din fortsatta anv&auml;ndning av denna webbplats efter n&aring;gon &auml;ndring av denna sekretesspolicy kommer att utg&ouml;ra din godtagande av s&aring;dan &auml;ndring.</p>
        <p>Om du har fr&aring;gor om &aring;tkomst till eller korrigering av dina personuppgifter, kontakta oss p&aring;: svenskbarter@gmail.com</p>
    </template>
</template>
    
<script>
export default {
  methods: {
    isSBProject () {
      if (process.env.VUE_APP_NAME === 'SB') {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>  
    h1 {
        padding: 10px 0px 10px 0px;
        font-size: 1.6rem;
    }
    h2 {
        padding: 10px 0px 10px 0px;
        font-size: 1.4rem;
    }
    h3 {
        padding: 10px 0px 10px 0px;
        font-size: 1.2rem;
        font-weight: bold;
    }
    p {
        padding: 0px;
        font-size: 1rem;
        font-weight: normal;
    }
</style>
